import React from "react";
import { useIsDesktopSmall } from "../../../hooks/common.hooks";
import ScenarioListCarousel from "../borrowing-capacity/components/scenario-list carousel";

const ApplicationMobileLayout = ({ children }) => {
  const isDestopSmall = useIsDesktopSmall();
  return (
    <>
      {isDestopSmall && (
        <div className="scenario-list">
          <div className="scenario-list__title">Scenario</div>
          <ScenarioListCarousel />
        </div>
      )}

      {children}
    </>
  );
};

ApplicationMobileLayout.propTypes = {};

export default ApplicationMobileLayout;
