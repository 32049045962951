import React from "react";
import { primaryColor } from "../../../../scss/colors.scss";
import Plus from "../../../../svgs/Plus";

const AddScenarioButton = ({
  title = "Compare Loan Options",
  disabled = false,
  handleOpenScenario,
}) => {
  const handleAddScenario = (value) => () => {
    if (disabled) {
      return;
    }
    handleOpenScenario(value);
  };

  return (
    <>
      <div
        className="add-scenario"
        role="button"
        disabled={disabled}
        onClick={handleAddScenario(true)}
        onKeyDown={() => {}}
        tabIndex={0}
      >
        <div>{title}</div>
        <div className="plus-icon">
          <Plus fill={primaryColor} />
        </div>
      </div>
    </>
  );
};

AddScenarioButton.propTypes = {};

export default AddScenarioButton;
