import React from "react";
import PropTypes from "prop-types";
import { formatNumberWithDollar } from "../utils/numberFormater";

const MobileChartTitle = ({ title = "", subTitle = "", value = 0 }) => {
  return (
    <>
      <div className="mobile-chart-title">
        <span className="mobile-chart-title__bold">{title}</span> {subTitle}
      </div>
      <div className="mobile-chart-value">{formatNumberWithDollar(value)}</div>
    </>
  );
};

MobileChartTitle.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  value: PropTypes.number,
};

export default MobileChartTitle;
