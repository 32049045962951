import PropTypes from "prop-types";
import merge from "lodash/merge";
import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { borderColor } from "../scss/colors.scss";
import { monthFormater } from "../utils/calendar-util";
import { useCompanyTheme } from "../hooks";

const buildChartData = (lineChartData, defaultColor) => {
  return {
    datasets: lineChartData.map((chartData) => {
      const {
        label = "",
        data = [],
        color = defaultColor,
        lineSpacing = [],
        borderWidth = 2,
        customOptions = {},
      } = chartData;

      return {
        label,
        fill: false,
        backgroundColor: color,
        borderColor: color,
        borderCapStyle: "butt",
        borderDash: lineSpacing,
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: color,
        pointBackgroundColor: color,
        pointBorderWidth: 0,
        pointHoverRadius: 6,
        pointHoverBackgroundColor: "#ffffff",
        pointHoverBorderColor: color,
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        borderWidth,
        pointHitRadius: 3,
        data,
        ...customOptions,
      };
    }),
  };
};

const buildChartOption = (
  handleSetChartTitleValue,
  minChartData,
  maxChartData,
  customChartOptions
) => {
  const baseOptions = {
    layout: {
      padding: {
        left: 30,
        right: 30,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      xAxes: [
        {
          display: false,
          gridLines: {
            display: false,
          },
          ticks: { padding: 25 },
          stacked: true,
          bounds: "data",
          type: "time",
          distribution: "linear",
        },
      ],
      yAxes: [
        {
          type: "linear",
          display: false,
          position: "right",
          ticks: {
            padding: 25,
            min: minChartData,
            max: maxChartData,
          },
          labels: {
            show: true,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      enabled: false,
    },
    legend: false,
    legendCallback: (chart) => {
      let text = "";
      for (let i = 0; i < chart.data.datasets.length; i += 1) {
        text += `<div class='legend-wrap'><div class="legend-box ${
          chart.data.datasets[i].borderDash.length ? "line-chart-dash" : ""
        }" ${
          chart.data.datasets[i].borderDash.length
            ? ""
            : `style="background-color:${chart.data.datasets[i].borderColor}"`
        }></div><div class="legend-font">`;
        if (chart.data.datasets[i].label) {
          text += `${chart.data.datasets[i].label}</div></div>`;
        }
      }
      return text;
    },
    hover: {
      mode: "nearest",
      intersect: false,
    },
    onHover: (event, activeElements) => {
      const datasetIndex = activeElements[0]?._datasetIndex || 0;
      if (activeElements.length) {
        handleSetChartTitleValue({
          index: datasetIndex,
          value:
            activeElements[0]._chart.config.data.datasets[datasetIndex].data[
              activeElements[0]._index
            ].y,
        });
      }
    },
  };
  return merge(baseOptions, customChartOptions);
};

const buildChartLegend = (legendDisplay) => {
  return {
    display: legendDisplay,
    position: "bottom",
    fullWidth: true,
    reverse: false,
  };
};

const drawVerticalLine = {
  beforeDraw(chart) {
    const chartType = chart.config.type;
    if (chart.active && chart.active.length && chartType === "line") {
      const activePoint = chart.active[0];
      const chartData = activePoint._chart.config.data.datasets[0].data;
      const activeIndex =
        activePoint._index < chartData.length
          ? activePoint._index
          : chartData.length - 1;
      const date = monthFormater(new Date(chartData[activeIndex]?.x), "/");
      const { ctx } = chart.chart;
      const yAxis = chart.scales["y-axis-0"];
      const { x } = activePoint.tooltipPosition();
      const topY = yAxis.top;
      const bottomY = yAxis.bottom;
      // draw line
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY - 20);
      ctx.lineWidth = 2;
      ctx.strokeStyle = borderColor;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.font = "14px ProximaNovaRegular";
      ctx.fillText(date, x, bottomY - 10);
      ctx.stroke();
      ctx.restore();
    }
  },
};

const getMinMaxInArrayByKey = (math, array, property) => {
  return Math[math](
    ...array?.map((chartItem) => {
      return Math[math](...chartItem.data?.map((item) => item[property]));
    })
  );
};

const LineChartMobileComponent = React.memo(
  ({
    lineChartData = [],
    legendDisplay = true,
    setChartTitleValue = () => {},
    customChartOptions = {},
  }) => {
    const [legend, setLegend] = useState("");
    const lineChart = useRef(null);
    const { secondaryColor } = useCompanyTheme();
    useEffect(() => {
      if (lineChart.current) {
        setLegend(lineChart.current?.chartInstance?.generateLegend());
      }
    }, []);

    // get the min point on the line Chart Data
    const minChartData = getMinMaxInArrayByKey("min", lineChartData, "y");
    // get the max point on the line Chart Data
    const maxChartData = getMinMaxInArrayByKey("max", lineChartData, "y");
    const yAxisInnerPadding = Math.max(
      minChartData * 0.2,
      (maxChartData - minChartData) * 0.2
    );

    const chartData = buildChartData(lineChartData, secondaryColor);
    const chartOption = buildChartOption(
      setChartTitleValue,
      minChartData - yAxisInnerPadding * 0.2,
      maxChartData + yAxisInnerPadding * 0.2,
      customChartOptions
    );

    const chartLegend = buildChartLegend(legendDisplay);

    return (
      <div className="line-chart-container">
        <div className="line-chart-size">
          <Line
            ref={lineChart}
            data={chartData}
            options={chartOption}
            legend={chartLegend}
            height={null}
            width={null}
            plugins={[drawVerticalLine]}
          />
        </div>
        <div
          className="legend line-chart-legend"
          dangerouslySetInnerHTML={{ __html: legend }}
        />
      </div>
    );
  }
);

LineChartMobileComponent.displayName = "LineChartMobileComponent";

export default LineChartMobileComponent;

LineChartMobileComponent.propTypes = {
  lineChartData: PropTypes.array,
  legendDisplay: PropTypes.bool,
  setChartTitleValue: PropTypes.func,
};
