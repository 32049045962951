import React, { useContext } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import DataLoadingContext from "../../../../context/data-loading-context";
import { FinanceContext } from "../../../../context/finance-context";
import ScenarioItemContext from "../../../../context/scenario-item-context";
import { useSortedScenarioList } from "../../../../hooks/scenario.hooks";
import AddScenarioButton from "./add-scenario-button";
import RemoveScenarioComponent from "./remove-scenario-component";
import ScenarioItem from "./scenario-item";

const ScenarioListCarousel = () => {
  const { pendingToCreateScenarios } = useContext(DataLoadingContext);
  const scenarioList = useSortedScenarioList();
  const { selectedScenarioId = "", setSelectedScenarioId } = useContext(
    FinanceContext
  );
  const {
    setScenarioFilterOpen,
    tabIndex,
    setTabIndex,
    setDialogOpen,
    dialogOpen,
  } = useContext(ScenarioItemContext);

  const handleOpenDialog = (value) => {
    setDialogOpen(value);
  };
  const handleSelectScenario = (value) => {
    setSelectedScenarioId(value);
  };

  const handleOpenScenario = (value) => {
    setTabIndex((prevState) => {
      if (prevState !== "buying-power") {
        return "buying-power";
      }
      return prevState;
    });
    setScenarioFilterOpen(value);
  };
  return (
    <ScrollContainer
      horizontal
      className="scenarios-scroll-container scroll-container"
    >
      <div className="scenario-list__container ">
        {scenarioList?.length > 0 &&
          scenarioList.map((item) => {
            return (
              <ScenarioItem
                key={`ScenarioItem+${item.id}`}
                scenarioDetail={item}
                selectedScenario={selectedScenarioId}
                handleSelectScenario={handleSelectScenario}
                isShowEditScenarioDialog={tabIndex === "buying-power"}
              />
            );
          })}
        <AddScenarioButton
          disabled={pendingToCreateScenarios?.length > 0}
          handleOpenScenario={handleOpenScenario}
        />
        <RemoveScenarioComponent
          open={dialogOpen}
          handleDialogClose={handleOpenDialog}
        />
      </div>
    </ScrollContainer>
  );
};

export default ScenarioListCarousel;
