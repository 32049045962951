import PropTypes from "prop-types";
import React, { useState } from "react";
import LineChartMobileComponent from "./line-chart-mobile-component";
import MobileChartTitle from "./mobile-chart-title";

const LineChartMobileWrapper = ({
  chartTitle = "",
  subTitle,
  titleValue = 0,
  chartData = [],
  customChartOptions,
}) => {
  const [lineChartTitleValue, setLineChartTitleValue] = useState({
    index: 0,
    value: 0,
  });

  const handleSetChartValueData = (value) => {
    setLineChartTitleValue(value);
  };
  return (
    <>
      <MobileChartTitle
        title={chartData?.[lineChartTitleValue.index]?.label || chartTitle}
        subTitle={subTitle}
        value={lineChartTitleValue?.value || titleValue}
      />
      <LineChartMobileComponent
        lineChartData={chartData}
        setChartTitleValue={handleSetChartValueData}
        customChartOptions={customChartOptions}
      />
    </>
  );
};

LineChartMobileWrapper.propTypes = {
  chartTitle: PropTypes.string,
  subTitle: PropTypes.string,
  titleValue: PropTypes.number,
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.any,
          y: PropTypes.number,
        })
      ),
      label: PropTypes.string,
      customOptions: PropTypes.object,
    })
  ),
};

export default LineChartMobileWrapper;
export { LineChartMobileWrapper };
